import React, { useEffect, useState, useContext } from 'react'
import { navigate } from 'gatsby'
import { isLoggedIn, getUser } from '../../services/auth'
import { COLORS, SPACING } from '../../helpers/constants'
import { useTranslation } from '../../hooks/useTranslation'
import { AppContext, getCustomerOrders } from '../../config/context'

import { Container } from '../Container'
import { Heading } from '../Heading'
import { Divider } from '../Divider'
import { Table } from '../Table'
import { Row } from '../Row'
import { Text } from '../Text'

import { TextLink } from '../TextLink'
import { Column } from '../../blocks/Column'

import {
  StyledPageContent,
  WrapContent,
  StyledSpecificationsTable,
  HideMobile,
  HideDesktop,
  StyledSpan,
} from './style'

const Account = () => {
  const [state, dispatch] = useContext(AppContext)
  let { orders } = state
  orders = orders ? orders : []

  const getTranslation = useTranslation()
  const [loading, setLoading] = useState(false)

  const [showOrderDetails, setShowOrderDetails] = useState(false)
  const { user_id, username } = getUser()

  const tHead = [
    { name: 'Order', key: 'id' },
    { name: 'Date', key: 'date_created' },
    { name: 'Status', key: 'status' },
    { name: '', key: '' },
  ]

  if (!isLoggedIn()) {
    if (typeof window !== 'undefined') {
      navigate('/login')
    }
  }

  useEffect(() => {
    console.log(user_id, 'user')
    dispatch(getCustomerOrders(user_id))
  }, [user_id, dispatch])

  useEffect(() => {
    if (!orders) {
      setLoading(true)
      dispatch(getCustomerOrders(user_id)).then(res => {
        setLoading(false)
      })
    }
  }, [orders, dispatch])

  const formatDate = date => {
    const dateInst = new Date(date)
    return dateInst.toLocaleString('default', {
      day: 'numeric',
      month: 'long',
      year: 'numeric',
    })
  }

  const formatName = address => {
    return `${address.first_name} ${address.last_name}`
  }

  const formatAddress = address => {
    return `${address.address_1}`
  }

  const formatState = address => {
    return `${address.postcode}, ${address.city}, ${address.country}`
  }

  const handleMoreInfo = order => {
    setShowOrderDetails(order)
  }

  const orderDetails = order => {
    const {
      id,
      total,
      currency_symbol,
      payment_method_title,
      shipping,
      total_tax,
      billing,
      line_items,
      date_created,
      customer_note,
    } = order

    const tableHead = [
      { name: 'product', key: 'name' },
      { name: 'quantity', key: 'quantity' },
      { name: 'article number', key: 'sku' },
      { name: 'Total incl VAT', key: 'subtotal' },
      { name: 'VAT', key: 'total_tax' },
    ]

    const orderLines = () => {
      return (
        <StyledSpecificationsTable>
          <Table>
            <Table.Head>
              <Table.Tr>
                {tableHead.map(head => {
                  return (
                    <Table.Th>
                      <Heading
                        size={6}
                        type={'h6'}
                        color={COLORS.PRIMARY_DARK_BLUE}
                      >
                        {head.name === 'quantity' ? (
                          <span style={{ paddingLeft: '90px' }}>
                            {getTranslation(head.name)}
                          </span>
                        ) : (
                          getTranslation(head.name)
                        )}
                      </Heading>
                    </Table.Th>
                  )
                })}
              </Table.Tr>
            </Table.Head>
            <Table.Body>
              {line_items.map((order, index) => {
                const {
                  name,
                  sku,
                  subtotal,
                  subtotal_tax,
                  quantity,
                  id,
                } = order

                const details = {
                  name: name,
                  sku: sku,
                  quantity: quantity,
                  subtotal: `${subtotal} ${currency_symbol}`,
                  subtotal_tax: `${subtotal_tax} ${currency_symbol}`,
                }
                return (
                  <>
                    <HideDesktop>
                      <Table.Tr key={index}>
                        {tableHead.map(item => {
                          return (
                            <Table.Th>
                              <div>
                                <span>{item.name}</span>:{' '}
                                <span>{details[item.key]}</span>
                              </div>
                            </Table.Th>
                          )
                        })}
                      </Table.Tr>
                    </HideDesktop>
                    <HideMobile>
                      <Table.Tr key={id}>
                        <WrapContent
                          onClick={() => {
                            handleMoreInfo(order)
                          }}
                        >
                          <Table.Td>{name}</Table.Td>
                        </WrapContent>
                        <Table.Td>
                          <span style={{ paddingLeft: '90px' }}>
                            {quantity}
                          </span>
                        </Table.Td>
                        <Table.Td>{sku}</Table.Td>
                        <Table.Td>
                          {total} {currency_symbol}
                        </Table.Td>
                        <Table.Td>
                          <span style={{ marginLeft: '-55px' }}>
                            {subtotal_tax} {currency_symbol}
                          </span>
                        </Table.Td>
                      </Table.Tr>
                    </HideMobile>
                  </>
                )
              })}
            </Table.Body>
          </Table>
        </StyledSpecificationsTable>
      )
    }

    return (
      <Container>
        <StyledPageContent>
          <Divider
            size={{
              desktop: SPACING.X_SMALL,
              tablet: SPACING.SMALL,
              mobile: SPACING.XXX_SMALL,
            }}
          />
          <Row responsive={true}>
            <Heading size={3} type={'h3'} color={COLORS.PRIMARY_RED}>
              {getTranslation('Order')}: {formatDate(date_created)}
            </Heading>
          </Row>
          <Divider
            size={{
              desktop: SPACING.MEDIUM,
              tablet: SPACING.SMALL,
              mobile: SPACING.SMALL,
            }}
          />
          <Row responsive={true}>
            <Column align={'flex-start'}>
              <Heading size={3} type={'h3'} color={COLORS.PRIMARY_RED}>
                {getTranslation('ORDER')}: {id}
              </Heading>
              <Divider size={SPACING.X_SMALL} />
              <Text>
                {getTranslation('Total incl VAT')}: {total} {currency_symbol}
              </Text>
              <Text>
                {getTranslation('VAT')}: {total_tax} {currency_symbol}
              </Text>
              <Text>
                {getTranslation('Date')}: {formatDate(date_created)}
              </Text>
              <Text>
                {getTranslation('Payment method')}: {payment_method_title}
              </Text>
            </Column>
            <Column align={'flex-start'}>
              <Heading size={3} type={'h3'} color={COLORS.PRIMARY_RED}>
                {getTranslation('PICKUP')}
              </Heading>
              <Divider size={SPACING.X_SMALL} />
              {customer_note ? (
                customer_note.split('\n').map(text => {
                  return <Text>{text}</Text>
                })
              ) : (
                <Text> {getTranslation('no info')} </Text>
              )}
            </Column>
            <Column align={'flex-start'}>
              <Heading size={3} type={'h3'} color={COLORS.PRIMARY_RED}>
                {getTranslation('BILLING')}
              </Heading>
              <Divider size={SPACING.X_SMALL} />
              <Text>{formatName(billing)}</Text>
              <Text>{billing.email}</Text>
              <Text>{billing.phone}</Text>
              <Text>{formatAddress(billing)}</Text>
              <Text>{formatState(billing)}</Text>
            </Column>
          </Row>
          <Divider
            size={{
              desktop: SPACING.LARGE,
              tablet: SPACING.SMALL,
              mobile: SPACING.SMALL,
            }}
          />
          {orderLines()}
          <Divider
            size={{
              desktop: SPACING.LARGE,
              tablet: SPACING.SMALL,
              mobile: SPACING.SMALL,
            }}
          />
          <Row responsive={true}>
            <WrapContent
              onClick={() => {
                handleMoreInfo(false)
              }}
            >
              <TextLink
                underline={false}
                icon={'keyboard_arrow_left'}
                iconSize={30}
                text={<StyledSpan>{getTranslation('Back')}</StyledSpan>}
              ></TextLink>
            </WrapContent>
          </Row>
          <Divider size={SPACING.XXX_LARGE} />
        </StyledPageContent>
      </Container>
    )
  }

  if (showOrderDetails) {
    return orderDetails(showOrderDetails)
  }

  return (
    <>
      <Container>
        <StyledPageContent>
          <Heading size={2} type={'h3'} color={COLORS.PRIMARY_RED}>
            {getTranslation('Hi')} {username},
          </Heading>
          <Divider size={SPACING.SMALL} />
          <StyledSpecificationsTable>
            <Table>
              <Table.Head>
                <Table.Tr>
                  {tHead.map(head => {
                    return (
                      <Table.Th>
                        <Heading
                          size={6}
                          type={'h6'}
                          color={COLORS.PRIMARY_DARK_BLUE}
                        >
                          {getTranslation(head.name)}
                        </Heading>
                      </Table.Th>
                    )
                  })}
                </Table.Tr>
              </Table.Head>
              <Table.Body>
                {loading ? (
                  <Table.Tr>
                    <Table.Td colspan={3}>
                      {getTranslation('loading...')}
                    </Table.Td>
                  </Table.Tr>
                ) : (
                  ''
                )}
                {!orders && !loading ? (
                  <Table.Tr>
                    <Table.Td colspan={3}>
                      {getTranslation('no orders')}
                    </Table.Td>
                  </Table.Tr>
                ) : (
                  ''
                )}
                {orders.map((order, index) => {
                  console.log(order, 'order')
                  const { id, date_created, status } = order
                  const orderDetails = {
                    id: id,
                    date_created: formatDate(date_created),
                    status: getTranslation(status.toLowerCase()),
                  }

                  return (
                    <>
                      <HideDesktop>
                        <Table.Tr key={index}>
                          <Table.Th>
                            {tHead.map((item, index) => {
                              if (!item.name) return
                              return (
                                <div>
                                  <span>{item.name}</span>:{' '}
                                  <span>
                                    {orderDetails[item.key]}{' '}
                                    {index === 2 ? (
                                      <span
                                        onClick={() => {
                                          handleMoreInfo(order)
                                        }}
                                        style={{
                                          float: 'right',
                                          cursor: 'pointer',
                                        }}
                                      >
                                        <TextLink
                                          text={getTranslation('more info')}
                                          underline={false}
                                          icon={'info'}
                                        ></TextLink>
                                      </span>
                                    ) : (
                                      ''
                                    )}
                                  </span>
                                </div>
                              )
                            })}
                          </Table.Th>
                        </Table.Tr>
                      </HideDesktop>
                      <HideMobile>
                        <Table.Tr key={id}>
                          <Table.Td>{id}</Table.Td>
                          <Table.Td>{formatDate(date_created)}</Table.Td>
                          <Table.Td>
                            {getTranslation(status.toLowerCase())}
                          </Table.Td>
                          <Table.Td>
                            <span
                              onClick={() => {
                                handleMoreInfo(order)
                              }}
                              style={{ float: 'right', cursor: 'pointer' }}
                            >
                              <TextLink
                                text={getTranslation('more info')}
                                underline={false}
                                icon={'info'}
                              ></TextLink>
                            </span>
                          </Table.Td>
                        </Table.Tr>
                      </HideMobile>
                      <HideDesktop>
                        <Divider size={SPACING.X_LARGE} />
                      </HideDesktop>
                    </>
                  )
                })}
              </Table.Body>
            </Table>
          </StyledSpecificationsTable>
          <Divider size={SPACING.XXX_LARGE} />
          <Divider size={SPACING.XXX_LARGE} />
        </StyledPageContent>
      </Container>
    </>
  )
}

export { Account }
